@import "./shared.module.scss";
@import "./wizard.respond.scss";

$wizardFrameEdge: 4%;
$wizardFrameEdgeBottom: 2%;

// :global(.root) {
// 	align-items: center !important;;
// }

.wizardFooter {
	display: flex;
	justify-content: space-between;
	font-size: 1rem;
    padding: 10px 0;
    width: 92%;
    max-width: 1440px;

	> div {
		display: flex;
		align-items: center;
	}

	a {
		color: black;
		text-decoration: none;
		font-weight: bold;

		&:hover {
			text-decoration: underline;
		}
	}

	img {
		padding: 0 10px;
	}
}

.wizardOuterContainer {
	width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
	align-items: center;
}

.wizardContainer {
	background-color: white;
	// height: calc(100% - 2*$wizardFrameEdge);
    width: calc(100% - 2*$wizardFrameEdge);
    border: 1px solid $chitChattrBlue50;
    border-radius: 50px;
    margin: $wizardFrameEdge $wizardFrameEdge $wizardFrameEdgeBottom $wizardFrameEdge;
    display: flex;
    justify-content: space-between;
    // align-items: center;
	overflow: hidden;
	max-width: 1440px;
	height: 100%;
}

.leftSidePanel {
	display: flex;
	flex-direction: column;
	padding: 6% 8%;
	justify-content: center;
	text-align: start;
}

.rightSidePanel {
	background-color: $chitChattrBlue500;
	height: 100%;
	width: clamp(10%, 38%, 38%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.centerPanel {
	display: flex;
	flex-direction: column;
	padding: 6% 8%;
	text-align: start;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.topArea {
	display: flex;
	justify-content: space-between;
}

.topLogoTitleArea {
	display: flex;
	padding-bottom: 30px;

	img {
		height: 47px;
		width: 47px;
	}

	h1 {
		border-left: 1px solid grey;
		line-height: 2.2rem;
		margin: auto 23px;
	}
}

.wizardStepsContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

	> div:last-child {
		height: 100%;

		> div {
			height: 100%;
		}
	}
}

.wizardStep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

h4 {
	font-weight: 100;
	font-size: 1.4rem;
}

.wizardTextboxContainer {
	padding-top: 20px;
	width: 300px;

	:global(.ms-TextField-fieldGroup) {
		border: 1px solid rgb(56 89 118);
		border-radius: 5px;
		height: 2rem;

		:global(.ms-TextField-field) {
			font-size: 1.2rem;
			font-weight: 600;
			font-family: "Source Sans Pro" !important;
		}
	}
}


.licenseAssignmentTypeOuterContainer {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 35px 0 20px 0;
}

.licenseAssignmentTypeContainer {
	box-sizing: border-box;
    width: 100%;
    max-width: 294px;
    min-width: 200px;
    height: 234px;
	background-color: #FFFFFF;
	color: $chitChattrBlue500;
	border: 1px solid $chitChattrBlue25;
	box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	text-align: center;
	padding: 30px;
    margin: 10px;
	display: flex;
    flex-direction: column;
    align-items: center;
	cursor: pointer;

	&:hover {
		// border: 2px solid $chitChattrBlue50;
		// box-shadow: none;
		box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03); 
	}

	&.active {
		// border: 2px solid $chitChattrBlue500;
		box-shadow: none;
		background-color: $chitChattrBlue500;
		color: #FFFFFF;

		.licenseAssignmentGraphicContainer {
			background-color: #FFFFFF;
		}
		
		.licenseAssignmentGraphic {
			color: $chitChattrBlue500;
		}
	}

	.licenseAssignmentGraphicContainer {
		background-color: $chitChattrBlue50;
		border-radius: 50%;
		width: 25px;
		padding: 14px;
		height: 25px;
		display: flex;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		margin: auto;
		justify-content: center;
	}

	.licenseAssignmentGraphic {
		font-size: 1.8rem;
		margin: auto;
		color: $chitChattrBlue500;
	}

	.licenseAssignmentTitle {
		padding: 20px;
		font-weight: 600;
		font-size: 1.2rem;
	}

	.licenseAssignmentDescription {
		flex: 1 1;
		font-size: 1rem;
	}
}

.subscriptionUsersOuterContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	> div {
		width: clamp(300px, 47%, 47%);
	}
}

.subscriptionUsersItem {
	display: flex;
	width: 100%;
	justify-content: space-between;

	&:hover {
		background-color: $chitChattrLightGray;
	}
}

.currentPeopleContainer {
	margin-left: 15px;
	overflow: auto;
	max-height: 70vw;
	padding: 10px;
	// border: 1px solid $chitChattrBlue50;
    // border-radius: 3px;
}

.thankYouOuterContainer {
	display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
	width: 52%;
    margin: auto;
    min-width: 287px;
    text-align: center;

	:global(.ms-Button--default) {
		margin: 10px;
	}

	:global(.ms-Button-label) {
		margin: 0 4px 0 2px;
	}
}