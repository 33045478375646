@import "./variables.scss";

@media (min-width: 453px) {
	.footerHelpLink {
		margin: auto 15px;
	}

	.header {
		h1 {
			font-size: 2rem;
			padding-left: 20px;
		}
	}
}

@media (max-width: 452px) {

	.header {
		h1 {
			font-size: 2rem;
			padding-left: 12px;
		}
	}

	.footerMadeByText {
		display: none;
	}

	.footerHelpLink {
		margin: auto 8px;
	}

	.consentSection {
		flex-direction: column;
	}
}