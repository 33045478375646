@import "../../styles/variables.scss";

.nav {
    // margin-bottom: 15px;
    // text-align: center;
	height: 14px;
}

.dot {
    color: black;
    cursor: pointer;
    font-size: 36px;
    line-height: 1;
    margin: 0 4px;
    // opacity: .4;
    text-shadow: none;
    transition: opacity 1s ease,
        text-shadow 1s ease;
    will-change: opacity, text-shadow;
	background-color: $neutral200;
    border-radius: 5px;
    width: 52px;
    height: 6px;
    display: inline-block;
}

.visited {
	background-color: $chitChattrBlue500;
}

.active {
	background-color: $chitChattrBlue500;
    // opacity: 1;
    // box-shadow: 0 0px 8px;
}