@import "./variables.scss";

@media (min-width: 453px) {
	.welcomeButton {
		padding-top: 6%;
	}

	.topLogoTitleArea {
		h1 {
			font-size: 1.7rem;
			padding: 0 0 0 20px;
		}
	}
}

@media (max-width: 452px) {
	.welcomeButton {
		padding-top: 24%;
	}

	.topLogoTitleArea {
		h1 {
			font-size: 1.5rem;
			padding: 0 0 0 15px;
		}
	}
}