@import "~@fluentui/react/dist/sass/References.scss";
@import "./variables.scss";
@import "./respond.scss";

html, body, :global(.root), :global(.App) {
	height: 100%;
	width: 100%;
}

body {
	color: $chitChattrBlue500 !important;
	font-family: "Source Sans Pro" !important;
	background-color: $chitChattrLightGray !important;
	margin: 0;
	font-size: 1.3em !important;
}

:global(.App) {
	display: flex;
    justify-content: center;
}

:global(.ms-Button-label) {
	font-size: 1.1rem !important;
    line-height: 1.5rem!important;
}

.pageContainer {
    background-color: white;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
	overflow: auto;
    // padding: 10px 5%;
}

.headerContainer {
	border-bottom: 1px solid $chitChattrBlue25;
	padding: 10px 2%;

	.header {
		display: flex;
		justify-content: space-between;
		max-width: 1440px;
		margin: auto;
	}

	img {
		width: 45px;
	}

	h1 {
		padding: 0;
		margin: 0;
		line-height: 2rem;
		margin: auto 0 10px 10px;
	}
}

.footerContainer {
	padding: 0 2%;
	// display: flex;
	// justify-content: space-between;
	// font-size: 1rem;
    // width: calc(100vw - 4%);

	.footer {
		padding: 10px 0;
		border-top: 1px solid $chitChattrBlue25;
		display: flex;
		justify-content: space-between;
		max-width: 1440px;
		margin: auto;

		> div {
			display: flex;
			align-items: center;
		}
	}

	a {
		color: black;
		text-decoration: none;
		font-weight: bold;

		&:hover {
			text-decoration: underline;
		}
	}

	img {
		padding: 0 10px;
	}

	// .footerMadeByText {
	// 	color: unset; // placeholder for responsive
	// }
}

.pageContentContainer {
	padding: 10px 5%;
    width: 90%;
    max-width: 1440px;
	margin: auto;
	flex: 1;
}

.avatarContainer {
	--padding: 3px;
}

h1 {
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	line-height: 60px;
	letter-spacing: -0.02em;
}

button {
	font-family: "Source Sans Pro" !important;
}

.row {
	padding: 10px 0;
}

:global(.ms-Button--default), :global(.ms-Button--primary) {
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding-bottom: 2px;
	width: fit-content;
}

.switchButtonContainer {
	padding: 20px 0;
	display: flex;
	flex-wrap: wrap;

	.switchButton {
		border-radius: 0;
		height: 37px;
		border-left-width: 0;
		border: 1px solid $gray300;
		// border-right-width: 0;
	
		&:first-of-type {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border-left-width: 1px;
		}
	
		&:last-of-type {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			border-right-width: 1px;
		}
	}

	:global(.ms-Button--primary) {
		border: 1px solid $chitChattrBlue500;
	}
}

.subscriptionAdminContainer {
	text-align: start;	
}

.subscriptionAdminHeaderContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 2%;
    padding-bottom: 5%;
	margin-top: -30px;

	> div {
		margin-top: 30px;
	}

	h2 {
		margin: 0;
	}

	:global(.ms-ComboBox-container) {
		display: inline-block;

		:global(.ms-ComboBox) {
		
			&::after {
				border-radius: 8px !important;
				height: 2.5rem;
			}
		}

		:global(.ms-Button) {
			padding-top: 10px;
		}

		:global(.ms-ComboBox-Input) {
			font-size: 1.9rem;
			font-weight: 600;
			font-family: "Source Sans Pro" !important;
			height: 2.5rem;
		}
	}

	:global(.ms-TextField-fieldGroup) {
		border: 1px dashed rgb(10, 24, 36);
		border-radius: 5px;
		height: 2.5rem;

		:global(.ms-TextField-field) {
			font-size: 1.9rem;
			font-weight: 600;
			font-family: "Source Sans Pro" !important;
		}
	}

	// button {
	// 	i {
	// 		font-size: 1.3rem;
	// 	}
	// }
}

.subscriptionAdminPeopleContainer {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-top: -30px;

	> div {
		width: 45%;
		padding: 0 10px;
		min-width: 349px;
		max-width: 600px;
		margin-top: 30px;
	}
}

.peopleListHeader {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: auto;
	align-items: center;

	:global(.ms-SearchBox) {
		border: 1px solid $chitChattrBlue25;
		border-radius: 5px;
	}
}

.peopleItemListContainer {
	margin-bottom: 10px;
	max-height: 51vh;
    overflow: auto;
}

.peopleItemContainer {
	border-top: 1px solid $chitChattrBlue25;
	display: flex;
	justify-content: space-between;
	margin-left: 6px;
	margin-right: 3px;
    height: 42px;
    padding-top: 15px;

	&:hover {
		background-color: $chitChattrLightGray;
	}
}

.neutral400 {
	color: $neutral400;
}

.success500 {
	color: $success500;
}

.consentSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
	border: 1px solid $chitChattrBlue25;
	border-radius: 8px;
	margin-bottom: 12px;

	.consentSectionTextblock {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex: 1;
		padding-left: 17px;
		padding-bottom: 20px;
	}

	h3 {
		margin: 3px 0 7px 0;
		font-size: 1.4rem;
	}
}

.consentMessageBar {
	min-height: fit-content;
}

:global(.ms-MessageBar) {
	// width: 96%;
    margin: 5px auto 0 auto;
    border-radius: 5px;
    padding: 0 1px;
	font-weight: bold;
}

:global(.ms-MessageBar-innerText) {
	// font-weight: bold;
	font-size: 1.1rem;
}

:global(.ms-MessageBar--error), :global(.ms-MessageBar--blocked) {
    color: rgb(196, 49, 75);
    border: 1px solid rgb(196, 49, 75);
}

:global(.ms-MessageBar--warning) {
    color: #ea6c12;
    border: 1px solid #ea6c12;
}

:global(mgt-people-picker) {
	--input-border: 1px solid #E8EBED;
	border-radius: 5px;
}

a {
	color: $chitChattrBlue500;

	&:visited {
		color: $chitChattrBlue500;
	}
}