@import "~@fluentui/react/dist/sass/References.scss";

.personContainer {
	display: flex;
}

.avatarContainer {
	display: flex;
	width: 24px;
	height: 24px;
	justify-content: center;
	margin: auto;
	border-radius: 50%;
	
	img {
		object-fit: cover;
		object-position: center top;
		border-radius: 50%;
	}

	&.pinkRed10 {
		background-color: var($ms-color-sharedPinkRed10);
	}
	
	&.red20 {
		background-color: var($ms-color-sharedRed20);
	}
	
	&.red10 {
		background-color: var($ms-color-sharedRed10);
	}
	
	&.orange20 {
		background-color: var($ms-color-sharedOrange20);
	}
	
	&.orangeYellow20 {
		background-color: var($ms-color-sharedOrangeYellow20);
	}
	
	&.green10 {
		background-color: var($ms-color-sharedGreen10);
	}
	
	&.green20 {
		background-color: var($ms-color-sharedGreen20);
	}
	
	&.cyan20 {
		background-color: var($ms-color-sharedCyan20);
	}
	
	&.cyan30 {
		background-color: var($ms-color-sharedCyan30);
	}
	
	&.cyanBlue10 {
		background-color: var($ms-color-sharedCyanBlue10);
	}
	
	&.cyanBlue20 {
		background-color: var($ms-color-sharedCyanBlue20);
	}
	
	&.blue10 {
		background-color: var($ms-color-sharedBlue10);
	}
	
	&.blueMagenta30 {
		background-color: var($ms-color-sharedBlueMagenta30);
	}
	
	&.blueMagenta20 {
		background-color: var($ms-color-sharedBlueMagenta20);
	}
	
	&.magenta20 {
		background-color: var($ms-color-sharedMagenta20);
	}
	
	&.magenta10 {
		background-color: var($ms-color-sharedMagenta10);
	}
	
	&.magentaPink10 {
		background-color: var($ms-color-sharedMagentaPink10);
	}
	
	&.orange30 {
		background-color: var($ms-color-sharedOrange30);
	}
	
	&.gray30 {
		background-color: var($ms-color-sharedGray30);
	}
	
	&.gray20 {
		background-color: var($ms-color-sharedGray20);
	}
	
}
